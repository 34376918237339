<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/banner5_01.jpg" />
    <img alt="Vue logo" src="../assets/banner5_02.jpg" />
    <img alt="Vue logo" src="../assets/banner5_03.jpg" />
    <img alt="Vue logo" src="../assets/banner5_04.jpg" />
    <img alt="Vue logo" src="../assets/banner5_05.jpg" />
    <div class="bottom">
      <div class="bottom_text">
      <div style="margin-bottom:10px">
        广东亿鹏科技服务有限公司官方网站
      </div>
        <div>
          Copyright @ 2023-2024 广东亿鹏科技服务有限公司 All rights
          reserved.&nbsp;
          <a href="https://beian.miit.gov.cn" target="_blank">
            <img
              src="../assets/beiab.png"
              style="width: 18px; height: 18px; vertical-align: top;margin-right:5px"
            />
            <span>备案编号:粤ICP备2024226064号-1</span></a
          >
        </div>

        <div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  data() {
    return {};
  },
  mounted() {
    document.title = "广东亿鹏科技服务有限公司官方网站";
  },
  methods: {
    handleTo() {
      console.log("2");
      this.$router.push("https://beian.miit.gov.cn");
    },
  },
};
</script>
<style lang="scss">
.home {
  width: 100%;
  height: 100%;
  // padding: 0;

  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
    // padding: 0;
    // display: block;
  }
  .bottom {
    padding-bottom:10px;
    background-color: #1b263a;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    // position: relative;
    .bottom_text {
      color: #fefefe;
      // padding: 20px;
      a {
        text-decoration: none;
        color: #fefefe;
        height: 70px; // 必加
        width: 228px;
        text-align: left;
        img {
          height: 50px;
          width: 50px;
          vertical-align: middle; // 必加
        }
        span {
          display: inline-block; // 必加
        }
      }
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
  }
}
</style>
